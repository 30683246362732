// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 查询付费下载应用列表
export const queryPaidApp = (data: PaidType.ListParams) =>
  request('/payAdminServer/paidapp/selectPaidApp', null, 'POST_JSON', data)

// 添加付费下载应用
export const addPaidApp = (data: PaidType.AddParams) =>
  request('/payAdminServer/paidapp/addPaidApp', null, 'POST_JSON', data)

// 通过dbtId 查询付费下载应用信息
export const queryPaidAppInfo = (data: PaidType.InfoParams) =>
  request('/payAdminServer/paidapp/selectPaidAppInfo', null, 'POST_JSON', data)

// 查询付费下载趋势图
export const queryTrendChart = (data: PaidType.ChartParams) =>
  request('/payAdminServer/paidapp/selectTrendChart', null, 'POST_JSON', data)

// 查询付费下载维度表
export const queryDimensionChart = (data: PaidType.ChartParams) =>
  request('/payAdminServer/paidapp/selectDimensionChart', null, 'POST_JSON', data)

// 导出付费下载维度表
export const exportPaidDimensionChart = (data: PaidType.ChartParams) =>
  request('/payAdminServer/paidapp/exportDimensionChart', null, 'POST_DOWNLOAD', data)
